import { graphql } from 'gatsby'
import React from 'react'

import Layout from '../components/Layout'
import Posts from '../components/Posts'
import { IEdge, IPost } from '../interfaces'

interface IData {
  publishedPosts: {
    edges: IEdge[]
  }
}

const Blog = ({ data, location }: { data: IData; location: Location }) => {
  const { edges } = data.publishedPosts
  const posts = edges.map((edge: IEdge) => edge.node)

  return (
    <Layout location={location}>
      <Posts posts={posts} />
    </Layout>
  )
}

export const query = graphql`
  query {
    publishedPosts: allMarkdownRemark(
      filter: { frontmatter: { isDraft: { eq: false } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      edges {
        node {
          id
          html
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            isDraft
            tags
            title
            timeToRead
            path
          }
        }
      }
    }
  }
`

export default Blog
